import { MainCont, Logo, CTAButton, Title, GoBack } from 'infrastructure/components';
import { useNavigate } from 'react-router-dom';
import { neveriKayakIcon } from 'infrastructure/assets';
import * as S from './NeveryKayak.styled';

const NeveryKayak = () => {
  const navigate = useNavigate();

  return (
    <MainCont color='sun yellow'>
      <S.NeveryKayakInnerCont>
        <Title
          style={{
            marginTop: '80px',
          }}
          titleProps={{
            text: 'Nevery Kayak',
          }}
        />
        <S.LogoCont>
          <Logo src={neveriKayakIcon} alt='Nevery Kayak Logo' />
        </S.LogoCont>
        <S.ButtonCont>
          <S.LinkTo to='tel:+584143807900' target='_blank'>
            <CTAButton
              CTAButtonProps={{
                $withBorder: true,
                text: 'Llamar',
                color: 'coral',
              }}
            />
          </S.LinkTo>

          <S.LinkTo to='https://wa.link/9tigal' target='_blank'>
            <CTAButton
              CTAButtonProps={{
                $withBorder: false,
                text: 'Whastapp',
                color: 'turquoise',
              }}
            />
          </S.LinkTo>
          <S.LinkTo to='https://www.instagram.com/neverikayak' target='_blank'>
            <CTAButton
              CTAButtonProps={{
                $withBorder: false,
                text: 'Instagram',
                color: 'blue',
              }}
            />
          </S.LinkTo>
        </S.ButtonCont>
        <GoBack
          onClick={() => navigate(-1)}
          gobackProps={{
            $arrowColor: 'coral',
          }}
        />
      </S.NeveryKayakInnerCont>
    </MainCont>
  );
};

export default NeveryKayak;
