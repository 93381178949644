import { MainCont, Logo, CTAButton, Title, GoBack } from 'infrastructure/components';
import { useNavigate } from 'react-router-dom';
import { altosCafeGourmetIcon } from 'infrastructure/assets';
import * as S from './RutasDelCafe.styled';

const RutasDelCafe = () => {
  const navigate = useNavigate();

  return (
    <MainCont>
      <S.RutasDelCafeInnerCont>
        <Title
          style={{
            marginTop: '80px',
          }}
          titleProps={{
            text: 'Rutas del Café',
          }}
        />
        <S.LogoCont>
          <Logo src={altosCafeGourmetIcon} alt='rutas del cafe Logo' />
        </S.LogoCont>
        <S.ButtonCont>
          <S.LinkTo to='tel:+584248545951' target='_blank'>
            <CTAButton
              CTAButtonProps={{
                $withBorder: true,
                text: 'Llamar',
                color: 'coral',
              }}
            />
          </S.LinkTo>
          <S.LinkTo to='https://wa.link/z8zl87' target='_blank'>
            <CTAButton
              CTAButtonProps={{
                $withBorder: false,
                text: 'Whastapp',
                color: 'turquoise',
              }}
            />
          </S.LinkTo>
          <S.LinkTo to='https://www.instagram.com/altoscafegourmet' target='_blank'>
            <CTAButton
              CTAButtonProps={{
                $withBorder: false,
                text: 'Instagram',
                color: 'sun yellow',
              }}
            />
          </S.LinkTo>
        </S.ButtonCont>
        <GoBack
          onClick={() => navigate(-1)}
          gobackProps={{
            $arrowColor: 'coral',
          }}
        />
      </S.RutasDelCafeInnerCont>
    </MainCont>
  );
};

export default RutasDelCafe;
