import { Props, CTAButtonProps } from './interfaces';
import * as S from './CTAButton.styled';

const CTAButton = ({ CTAButtonProps, ...restProps }: Props) => {
  const { $withBorder, color, text }: CTAButtonProps = CTAButtonProps || {
    $withBorder: false,
    color: 'blue',
    text: 'test',
  };

  return (
    <S.CTAButton {...restProps} $withBorder={$withBorder} color={color}>
      {text}
    </S.CTAButton>
  );
};

export default CTAButton;
