import { useEffect, useState, useDeferredValue } from 'react';
import { FormValueProps, FormErrorsProps } from '../interfaces/interfaces';
import validation from '../utils/validation';
import { useSendRecomendation } from '../hooks';
import { CountryData } from 'react-phone-input-2';

const useForm = (initValues: FormValueProps, error: FormErrorsProps) => {
  const { sendRecomendation, isSendingRecomendation } = useSendRecomendation();

  //inputs state
  const [values, setValues] = useState<FormValueProps>({
    ...initValues,
  });

  const deferredValue = useDeferredValue<FormValueProps>(values);

  //inputs errors
  const [errors, setErrors] = useState<FormErrorsProps>({
    ...error,
  });

  //validation reset
  const [isValidating, setIsValidating] = useState<boolean>(false);

  //validation reset
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await sendRecomendation({ values, initValues, setIsValidating, setValues });
  };

  const inputHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    if (!isValidating) {
      setIsValidating(true);
    }

    setValues((prevFormData) => {
      return { ...prevFormData, [name]: type === 'checkbox' ? checked : value };
    });
  };

  const textAreaHandleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (!isValidating) {
      setIsValidating(true);
    }

    setValues((prevFormData) => {
      return { ...prevFormData, [name]: value };
    });
  };

  const phoneNumberHandleChange = (
    value: string,
    data: {} | CountryData,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    const { name } = event.target;
    if (!isValidating) {
      setIsValidating(true);
    }

    setValues((prevFormData) => {
      return { ...prevFormData, [name]: formattedValue };
    });
  };

  useEffect(() => {
    setErrors(isValidating ? validation(values) : { ...error });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferredValue]);

  useEffect(() => {
    setDisableSubmit(isValidating ? Object.values(errors).some((error) => error !== '') : true);
  }, [errors, isValidating]);

  return {
    values,
    errors,
    disableSubmit,
    isValidating,
    isSendingRecomendation,
    setValues,
    handleSubmit,
    phoneNumberHandleChange,
    textAreaHandleChange,
    inputHandleChange,
  };
};

export default useForm;
