import { MainCont, Logo, CTAButton, Title, GoBack } from 'infrastructure/components';
import { useNavigate } from 'react-router-dom';
import { lasOlasBlueIcon } from 'infrastructure/assets';
import * as S from './Recepcion.styled';

const Recepcion = () => {
  const navigate = useNavigate();

  return (
    <MainCont color='sand'>
      <S.RecepcionInnerCont>
        <Title
          style={{
            marginTop: '80px',
          }}
          titleProps={{
            color: 'blue',
            text: 'Recepción',
          }}
        />
        <S.LogoCont>
          <Logo src={lasOlasBlueIcon} alt='Las Olas complete Logo' />
        </S.LogoCont>
        <S.ButtonCont>
          <S.LinkTo to='tel:+584248440112' target='_blank'>
            <CTAButton
              CTAButtonProps={{
                $withBorder: false,
                text: 'Llamar',
                color: 'coral',
              }}
            />
          </S.LinkTo>

          <S.LinkTo to='https://wa.link/xh94k3' target='_blank'>
            <CTAButton
              CTAButtonProps={{
                $withBorder: false,
                text: 'Whastapp',
                color: 'turquoise',
              }}
            />
          </S.LinkTo>
          <S.LinkTo to='https://www.instagram.com/lasolas.hotel' target='_blank'>
            <CTAButton
              CTAButtonProps={{
                $withBorder: false,
                text: 'Instagram',
                color: 'blue',
              }}
            />
          </S.LinkTo>
        </S.ButtonCont>
        <GoBack
          onClick={() => navigate(-1)}
          gobackProps={{
            $arrowColor: 'coral',
            $textColor: 'coral',
          }}
        />
      </S.RecepcionInnerCont>
    </MainCont>
  );
};

export default Recepcion;
