import {
  Registration,
  Selection,
  Recepcion,
  TamborChino,
  NeveryKayak,
  MasterDrivers,
  Activities,
  GrupoTuristicoBahia,
  SenderosAnzoategui,
  RutasDelCafe,
  BeachTourLecheria,
  Recomendaciones,
} from 'infrastructure/pages';
import { LayoutRoot, MainLayout } from 'infrastructure/layout';
import { RoutesProps } from './_interfaces';

const homeRoutes: RoutesProps[] = [
  {
    path: '/',
    Component: Selection,
  },
  {
    path: '/recepcion',
    Component: Recepcion,
  },
  {
    path: '/tambor-chino',
    Component: TamborChino,
  },
  {
    path: '/neveri-kayak',
    Component: NeveryKayak,
  },

  {
    path: '/master-driver',
    Component: MasterDrivers,
  },
  {
    path: '/actividades',
    Component: Activities,
  },

  {
    path: '/actividades/grupo-turistico-bahia',
    Component: GrupoTuristicoBahia,
  },
  {
    path: '/actividades/senderos-anzoategui',
    Component: SenderosAnzoategui,
  },
  {
    path: '/actividades/rutas-del-cafe',
    Component: RutasDelCafe,
  },
  {
    path: '/actividades/beach-tour-lecheria',
    Component: BeachTourLecheria,
  },
  {
    path: '/recomendaciones',
    Component: Recomendaciones,
  },
];

const mainRoutes: RoutesProps[] = [
  {
    path: '/Registration',
    Component: Registration,
  },
  {
    path: '/',
    Component: MainLayout,
    nestedRoutes: homeRoutes,
    isProtected: true,
  },
];

const rootRoutes: RoutesProps[] = [
  {
    path: '/',
    Component: LayoutRoot,
    nestedRoutes: mainRoutes,
  },
];

export default rootRoutes;
