import styled, { css } from 'styled-components';
import { TiHome } from 'react-icons/ti';
import { Link } from 'react-router-dom';
import { GoHomeProps } from './interfaces';

export const Footer = styled.footer`
  background-color: var(--color-blue);
  grid-area: footer;
  height: 100px;
  width: min(100%, 600px);
  align-self: flex-end;
  position: relative;
`;

export const GoHome = styled(TiHome).attrs((props: GoHomeProps) => {
  return props;
})`
  color: var(--color-coral);
  font-size: 40px;
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;
  background-color: var(--color-sun-yellow);
  border: 1px solid white;
  border-radius: 50%;
  padding: 5px;

  ${({ $homeColor }) =>
    $homeColor === 'sun yellow'
      ? css`
          color: var(--color-sun-yellow);
        `
      : $homeColor === 'turquoise'
      ? css`
          color: var(--color-turquoise);
        `
      : $homeColor === 'coral'
      ? css`
          color: var(--color-coral);
        `
      : $homeColor === 'sand'
      ? css`
          color: var(--color-sand);
        `
      : $homeColor === 'blue'
      ? css`
          color: var(--color-blue);
        `
      : css`
          color: white;
        `};
`;

export const LinkTo = styled(Link)``;
