import styled from 'styled-components';

interface MainContProps {
  color?: 'blue' | 'turquoise' | 'coral' | 'sand' | 'sun yellow';
}

export const MainCont = styled.section<MainContProps>`
  grid-area: mainCont;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px 20px 40px 20px;
  width: min(100%, 600px);
  background-color: ${({ color }) =>
    color === 'sun yellow'
      ? 'var(--color-sun-yellow)'
      : color === 'turquoise'
      ? 'var(--color-turquoise)'
      : color === 'coral'
      ? 'var(--color-coral)'
      : color === 'sand'
      ? 'var(--color-sand)'
      : 'var(--color-blue)'};
  /* border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px; */
`;
