import { MainCont, Logo, CTAButton, Title, GoBack } from 'infrastructure/components';
import { useNavigate } from 'react-router-dom';
import { beachTourLecheria } from 'infrastructure/assets';
import * as S from './BeachTourLecheria.styled';

const BeachTourLecheria = () => {
  const navigate = useNavigate();

  return (
    <MainCont color='coral'>
      <S.BeachTourLecheriaInnerCont>
        <Title
          style={{
            marginTop: '80px',
          }}
          titleProps={{
            text: 'Beach Tour Lecheria',
          }}
        />
        <S.LogoCont>
          <Logo src={beachTourLecheria} alt='Beach Tour Lecheria  Logo' />
        </S.LogoCont>
        <S.ButtonCont>
          <S.LinkTo to='tel:+584248246266'>
            <CTAButton
              CTAButtonProps={{
                $withBorder: true,
                text: 'Llamar',
                color: 'sun yellow',
              }}
            />
          </S.LinkTo>

          <S.LinkTo to='https://wa.link/oa7up3' target='_blank'>
            <CTAButton
              CTAButtonProps={{
                $withBorder: false,
                text: 'Whastapp',
                color: 'turquoise',
              }}
            />
          </S.LinkTo>
          <S.LinkTo to='https://www.instagram.com/beachtourlecheria' target='_blank'>
            <CTAButton
              CTAButtonProps={{
                $withBorder: false,
                text: 'Instagram',
                color: 'blue',
              }}
            />
          </S.LinkTo>
        </S.ButtonCont>
        <GoBack
          onClick={() => navigate(-1)}
          gobackProps={{
            $arrowColor: 'sun yellow',
          }}
        />
      </S.BeachTourLecheriaInnerCont>
    </MainCont>
  );
};

export default BeachTourLecheria;
