import styled, { css } from 'styled-components';
import { TitleProps } from './interfaces';

export const Title = styled.h1.attrs((props: TitleProps) => {
  return props;
})`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  max-width: 335px;

  @media screen and (min-width: 600px) {
    font-size: 25px;
    max-width: 420px;
  }

  ${({ color }) =>
    color === 'sun yellow'
      ? css`
          color: var(--color-sun-yellow);
        `
      : color === 'turquoise'
      ? css`
          color: var(--color-turquoise);
        `
      : color === 'coral'
      ? css`
          color: var(--color-coral);
        `
      : color === 'sand'
      ? css`
          color: var(--color-sand);
        `
      : color === 'blue'
      ? css`
          color: var(--color-blue);
        `
      : css`
          color: var(--color-white);
        `};
`;
