import { MainCont, Logo, Title, RecomendationForm } from 'infrastructure/components';
import { lasOlasIcon } from 'infrastructure/assets';
import * as S from './Recomendaciones.style';

const Recomendaciones = () => {
  return (
    <MainCont color='sun yellow'>
      <S.RecomendacionesInnerCont>
        <S.LogoCont>
          <Logo src={lasOlasIcon} alt='Las Olas Logo' />
        </S.LogoCont>
        <S.GreetingCont>
          <Title
            style={{
              textAlign: 'center',
            }}
            titleProps={{
              text: 'Recomendaciones',
            }}
          />
        </S.GreetingCont>
        <RecomendationForm />
      </S.RecomendacionesInnerCont>
    </MainCont>
  );
};

export default Recomendaciones;
