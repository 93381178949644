import styled, { css } from 'styled-components';
import { SelectionButtonProps } from './interfaces';

export const SelectionButton = styled.button.attrs((props: SelectionButtonProps) => {
  return props;
})`
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-family: inherit;
  height: 50px;
  width: 100%;
  font-size: 18px;
  letter-spacing: 1px;
  border-radius: 8px;
  border: none;
  cursor: pointer;

  ${({ color }) =>
    color === 'sun yellow'
      ? css`
          background-color: var(--color-sun-yellow);
        `
      : color === 'turquoise'
      ? css`
          background-color: var(--color-turquoise);
        `
      : color === 'coral'
      ? css`
          background-color: var(--color-coral);
        `
      : color === 'sand'
      ? css`
          background-color: var(--color-sand);
          color: var(--color-blue);
        `
      : css`
          background-color: var(--color-blue);
        `};
`;
