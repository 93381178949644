import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';
import { SoftRegistration } from '../interfaces';
import axios from 'axios';
import { useState } from 'react';

const useSoftRegistration = () => {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [isRegistering, SetIsRegistering] = useState(false);

  const softRegistration = async ({
    values,
    initValues,
    setIsValidating,
    setValues,
  }: SoftRegistration) => {
    SetIsRegistering(true);

    try {
      const dataToSend = { ...values, type: 'contact' };

      await axios.post('https://las-olas.up.railway.app/api', dataToSend);

      localStorage.setItem('previousSend', JSON.stringify(true));

      SetIsRegistering(false);
      MySwal.fire({
        toast: true,
        icon: 'success',
        title: 'Felicidades, ahora puede disfrutar de nuestros servicios',
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
      });
      navigate('/selection');
    } catch (error) {
      let errorMessage =
        'Opps, a ocurrido un error, por favor verifique su conexión a internet e intente nuevamente';
      SetIsRegistering(false);
      MySwal.fire({
        toast: true,
        icon: 'error',
        title: errorMessage,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
    } finally {
      setIsValidating(false);
      setValues({ ...initValues });
    }
  };

  return { softRegistration, isRegistering };
};

export default useSoftRegistration;
