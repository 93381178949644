import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import styled, { css } from 'styled-components';

export const MasterDriversInnerCont = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow-y: auto; */
`;

export const LogoCont = styled.div`
  margin-top: 30px;
  width: 190px;
  margin-bottom: 20px;

  @media screen and (min-width: 600px) {
    transform: scale(1.2);
  }
`;

export const ButtonCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(100%, 350px);

  & a {
    margin-bottom: 15px;
  }
`;

interface LinkToProps {
  position?: 'active' | 'left' | 'right';
}

export const LinkTo = styled(Link)<LinkToProps>`
  width: fit-content;

  ${({ position }) =>
    position === 'active'
      ? css`
          z-index: 5;
          opacity: 1;
          left: 0;
        `
      : position === 'left'
      ? css`
          z-index: 5;
          opacity: 0;
          left: -110%;
        `
      : position === 'right'
      ? css`
          z-index: 5;
          opacity: 0;
          left: 110%;
        `
      : null}
`;

export const ButtonCarouselCont = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;
export const ButtonCarouselLeft = styled(AiOutlineArrowLeft)`
  font-size: 25px;
  margin-right: 10px;
  cursor: pointer;
`;

export const ButtonCarouselRight = styled(AiOutlineArrowRight)`
  font-size: 25px;
  margin-left: 10px;
  cursor: pointer;
`;

export const ButtoCarouselInnerCont = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  width: 210px;
  height: 55px;

  & a {
    position: absolute;
    margin: 0;
    transition: all 0.5s linear;
  }
`;
