import { useEffect, useState } from 'react';
import { Props } from '../interfaces';

const useTextArea = ({ formTextAreaProps, ...restProps }: Props) => {
  const [focused, setFocused] = useState(false);

  const handleFocusedBlur = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    setFocused(true);
  };
  const handleFocusedFocus = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    setFocused(true);
  };

  useEffect(() => {
    if (!formTextAreaProps.isValidating) {
      setFocused(false);
    }
  }, [formTextAreaProps.isValidating]);

  return {
    formTextAreaProps,
    restProps,
    focused,
    handleFocusedBlur,
    handleFocusedFocus,
  };
};

export default useTextArea;
