import styled, { css } from 'styled-components';
import { BsCheckCircleFill } from 'react-icons/bs';
import { InputProps } from 'react-html-props';

export const FormInput = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5px;

  &[data-ischeckbox='true'] {
    flex-direction: row-reverse;
    justify-content: flex-end;

    & label {
      font-size: 14px;
      cursor: pointer;
      font-weight: 500;
    }
  }
`;

export const Input = styled.input.attrs((props: InputProps) => {
  return { ...props };
})`
  padding: 6px 20px 6px 20px;
  margin: 0px 0px 25px 0px;
  border-radius: 7px;
  background-color: var(--color-white);
  border: 2px solid transparent;
  outline: none;
  font-weight: 600;
  font-family: inherit;
  font-size: 16px;
  color: var(--color-blue);
  transition: var(--transition);
  color-scheme: light;

  &::placeholder {
    color: var(--color-blue);
    font-weight: 600;
    opacity: 0.6;
    font-family: inherit;
    font-size: 16px;
    transition: var(--transition);
  }

  &:focus {
    border: 2px solid var(--color-turquoise);

    &::placeholder {
      opacity: 0.8;
      transition: var(--transition);
    }
  }

  /* &:active {
     background-color: var(--color-purple); 
  } 
  */

  &[disabled] {
    background-color: var(--color-grey);
    color: #625e5e;
    cursor: not-allowed;
  }

  &[data-focused='true'] ~ span {
    display: block;
  }

  &[data-focused='true'] {
    border: 2px solid #a23737;
  }

  /* &[data-pass='true'] {
    border: 2px solid #219533;
  } */

  &[type='checkbox'] {
    margin-right: 10px;
    cursor: pointer;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--color-soft-white);
    box-shadow: 0 0 0px 1000px var(--color-soft-black) inset;
    -webkit-box-shadow: 0 0 0px 1000px var(--color-soft-black) inset;
  }

  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px var(--color-soft-black) inset;
    -webkit-box-shadow: 0 0 0px 1000px var(--color-greyish-black) inset;
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  color: var(--color-soft-white);
`;

export const Span = styled.span`
  position: absolute;
  font-size: 14px;
  padding: 3px;
  color: var(--color-white);
  display: none;
  bottom: -2px;
  font-family: inherit;
`;

interface IsCheckProps {
  $pass: boolean;
  $top?: boolean;
}

export const IsCheck = styled(BsCheckCircleFill)<IsCheckProps>`
  position: absolute;
  padding-top: 1px;
  top: ${({ $top }) => ($top ? '50%' : '33%')};
  right: 10px;
  font-size: 18px;
  transform: translate(-50%, -50%);
  color: #219533;
  transition: var(--transition);
  ${({ $pass }) =>
    $pass
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
`;
