import styled from 'styled-components';

export const LayoutRoot = styled.div`
  display: grid;
  grid:
    [row1-start] 'mainCont' auto [row1-end]
    [row2-start] 'footer' 80px[row2-end]
    / auto;
  justify-items: center;
  margin: 0 auto;
  overflow-y: auto;
  background-color: #d2c4b3;
  height: 100vh;

  @media screen and (min-height: 900px) {
    align-content: center;
  }
`;
