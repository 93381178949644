import styled, { css } from 'styled-components';
import { SubTitleProps } from './interfaces';

export const SubTitle = styled.h2.attrs((props: SubTitleProps) => {
  return props;
})`
  font-size: 18px;
  font-weight: 500;
  max-width: 340px;

  @media screen and (min-width: 600px) {
    font-size: 22px;
    max-width: 420px;
  }

  ${({ color }) =>
    color === 'sun yellow'
      ? css`
          color: var(--color-sun-yellow);
        `
      : color === 'turquoise'
      ? css`
          color: var(--color-turquoise);
        `
      : color === 'coral'
      ? css`
          color: var(--color-coral);
        `
      : color === 'sand'
      ? css`
          color: var(--color-sand);
        `
      : color === 'blue'
      ? css`
          color: var(--color-blue);
        `
      : css`
          color: var(--color-white);
        `};
`;
