import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Props } from './interfaces';

const Meta = ({ children, title, author, description }: Props) => {
  return (
    <HelmetProvider>
      <>
        <Helmet prioritizeSeoTags>
          <title>{title}</title>
          <meta name='author' content={author} />
          <meta name='description' content={description} />
        </Helmet>
        <Helmet>
          <meta charSet='utf-8' />
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <meta name='theme-color' content='#000000' />
        </Helmet>
        <Helmet>
          <link rel='preconnect' href='https://fonts.googleapis.com' />
          <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin='anonymous' />
          <link
            href='https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'
            rel='stylesheet'
          />
        </Helmet>
        {children}
      </>
    </HelmetProvider>
  );
};

export default Meta;
