import { MainCont, Logo, SelectionButton, Title, GoBack } from 'infrastructure/components';
import { actividadesIcon } from 'infrastructure/assets';
import * as S from './Activities.styled';
import { useNavigate } from 'react-router-dom';

const Activities = () => {
  const navigate = useNavigate();

  return (
    <MainCont>
      <S.ActivitiesInnerCont>
        <Title
          style={{
            marginTop: '80px',
          }}
          titleProps={{
            text: 'Actividades',
          }}
        />
        <S.LogoCont>
          <Logo src={actividadesIcon} alt='Actividades Logo' />
        </S.LogoCont>
        <S.ButtonCont>
          <S.LinkTo to='grupo-turistico-bahia'>
            <SelectionButton
              selectionButtonProps={{
                text: 'Grupo Turístico Bahía',
                color: 'sand',
              }}
            />
          </S.LinkTo>
          <S.LinkTo to='senderos-anzoategui'>
            <SelectionButton
              selectionButtonProps={{
                text: 'Senderos Anzoátegui',
                color: 'sun yellow',
              }}
            />
          </S.LinkTo>
          <S.LinkTo to='rutas-del-cafe'>
            <SelectionButton
              selectionButtonProps={{
                text: 'Rutas del Café',
                color: 'turquoise',
              }}
            />
          </S.LinkTo>
          <S.LinkTo to='beach-tour-lecheria'>
            <SelectionButton
              selectionButtonProps={{
                text: 'Beach Tour Lechería',
                color: 'coral',
              }}
            />
          </S.LinkTo>
        </S.ButtonCont>
        <GoBack
          onClick={() => navigate(-1)}
          gobackProps={{
            $arrowColor: 'coral',
          }}
        />
      </S.ActivitiesInnerCont>
    </MainCont>
  );
};

export default Activities;
