import PhoneInput from 'react-phone-input-2';
import { useInput } from './hooks';

import styleE from './style/styleExtender.module.scss';
import { Props } from './interfaces';
import * as S from './FormPhoneInput.styled';
import './style/style.scss';

const FormPhoneInput = (formPhoneInputProps: Props) => {
  const {
    formInputProps: { label, errorMessage, withCheck, onChange, value, fromStartValidation },
    cleanRestProps,
    focused,
    handleFocusedBlur,
    handleFocusedFocus,
  } = useInput(formPhoneInputProps);

  return (
    <S.FormPhoneInputCont className={cleanRestProps.className} style={cleanRestProps.style}>
      {label ? <S.Label htmlFor={cleanRestProps.id}>{label}</S.Label> : null}
      <PhoneInput
        inputProps={{ ...cleanRestProps, onBlur: handleFocusedBlur, onFocus: handleFocusedFocus }}
        inputClass={`${
          (fromStartValidation && !!errorMessage) || (!!errorMessage && focused)
            ? styleE.focused
            : !errorMessage && !!value
            ? styleE.pass
            : ''
        }`}
        data-focused={fromStartValidation ? !!errorMessage : !!errorMessage && focused}
        country={'ve'}
        autoFormat={false}
        value={value}
        onChange={onChange}
        data-pass={!errorMessage && !!value}
      />

      {withCheck ? (
        <S.IsCheck
          top={label ? 'true' : 'false'}
          pass={!errorMessage && value ? 'true' : 'false'}
        />
      ) : null}
      {(fromStartValidation && errorMessage) || (errorMessage && focused) ? (
        <S.Span>{errorMessage}</S.Span>
      ) : null}
    </S.FormPhoneInputCont>
  );
};

export default FormPhoneInput;
