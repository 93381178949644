import styled from 'styled-components';

export const RegistrationInnerCont = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow-y: auto; */
`;

export const LogoCont = styled.div`
  margin-top: 20px;
  width: 95px;

  @media screen and (min-width: 600px) {
    transform: scale(1.2);
  }
`;

export const GreetingCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0 0 0;

  @media screen and (min-width: 600px) {
    padding: 40px 0 0 0;
  }
`;

export const WifiIconCont = styled.div`
  margin-top: 10px;
  width: 30px;
  height: 30px;
`;

export const Text = styled.p`
  font-family: 'Avenir';
  font-family: inherit;
`;
