import { Suspense, useId } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import rootRoutes from './routes';
import { RoutesProps } from './_interfaces';
import { BeatLoader } from 'react-spinners';
import AppProtectedRoutes from './AppProtectedRoutes';

const AppRoutes = () => {
  const id = useId();
  //this components have all the routes of the layouts, those layout can be a components
  //or components with their own routes (like LayoutMain)

  // Scroll to top when route changes
  const routeCreator = (routesList: RoutesProps[]) => {
    return (
      <>
        {routesList.map(({ path, Component, nestedRoutes, isProtected }) => {
          return (
            <Route element={<AppProtectedRoutes isProtected={isProtected} />} key={`${id}-${path}`}>
              <Route path={path} element={<Component />}>
                {nestedRoutes && routeCreator(nestedRoutes)}
              </Route>
            </Route>
          );
        })}
      </>
    );
  };

  return (
    <Suspense
      fallback={
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'var(--color-soft-black)',
          }}
        >
          <BeatLoader color='#ffffff50' margin={3} speedMultiplier={0.6} />
        </div>
      }
    >
      <Routes>
        {routeCreator(rootRoutes)}
        <Route path='/*' element={<Navigate to='/' />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
