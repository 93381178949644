import { useId } from 'react';
import { FormInput, SelectionButton, FormTextArea } from 'infrastructure/components';
import { useForm } from './hooks';
import { formValues, formErrors } from './utils';

import * as S from './RecomendationFormForm.styled';
import { BeatLoader } from 'react-spinners';

const RecomendationsForm = () => {
  const inputId = useId();
  const {
    values,
    errors,
    disableSubmit,
    isValidating,
    isSendingRecomendation,
    inputHandleChange,
    textAreaHandleChange,
    handleSubmit,
  } = useForm(formValues, formErrors);

  return (
    <S.Form onSubmit={handleSubmit}>
      <S.FieldSet>
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-nameInput`}
            name='name'
            type='text'
            placeholder='Nombre'
            onChange={inputHandleChange}
            value={values.name}
            required
            formInputProps={{
              errorMessage: errors.name,
              withCheck: true,
              isValidating,
            }}
          />
        </S.InputsContainer>
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-lastnameInput`}
            name='lastname'
            type='text'
            placeholder='Apellido'
            onChange={inputHandleChange}
            value={values.lastname}
            required
            formInputProps={{
              errorMessage: errors.lastname,
              withCheck: true,
              isValidating,
            }}
          />
        </S.InputsContainer>
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-emailInput`}
            name='email'
            type='text'
            placeholder='Email'
            onChange={inputHandleChange}
            value={values.email}
            required
            formInputProps={{
              errorMessage: errors.email,
              withCheck: true,
              isValidating,
            }}
          />
        </S.InputsContainer>

        <S.InputsContainer>
          <FormTextArea
            id={`${inputId}-msjInput`}
            name='message'
            onChange={textAreaHandleChange}
            value={values.message}
            placeholder='Deja tu comentario'
            required
            formTextAreaProps={{
              errorMessage: errors.message,
              withCheck: true,
              isValidating,
            }}
          />
        </S.InputsContainer>
      </S.FieldSet>

      {isSendingRecomendation ? (
        <S.SpinnerCont>
          <BeatLoader color='#ffffff50' margin={3} speedMultiplier={0.6} />
        </S.SpinnerCont>
      ) : (
        <SelectionButton
          selectionButtonProps={{
            text: 'Enviar',
            color: 'blue',
          }}
          disabled={disableSubmit}
        />
      )}
    </S.Form>
  );
};

export default RecomendationsForm;
