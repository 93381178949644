import styled from 'styled-components';

export const RecomendacionesInnerCont = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow-y: auto; */
`;

export const LogoCont = styled.div`
  margin-top: 10px;
  width: 95px;
`;

export const GreetingCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0 0 0;
`;

export const WifiIconCont = styled.div`
  margin-top: 10px;
  width: 30px;
  height: 30px;
`;

export const Text = styled.p`
  font-family: 'Avenir';
  font-family: inherit;
`;
