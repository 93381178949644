import * as S from './Title.styled';
import { Props, TitleProps } from './interfaces';

const Title = ({ titleProps, ...restProps }: Props) => {
  const { text, color }: TitleProps = titleProps || {
    text: 'Titulo',
    color: 'blue',
  };

  return (
    <S.Title {...restProps} color={color}>
      {text}
    </S.Title>
  );
};

export default Title;
