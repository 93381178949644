import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 0px;
  border-radius: 10px;
  width: min(100%, 600px);
  z-index: 1;
  margin: 0 auto;
  width: min(100%, 350px);

  & button {
    margin: auto 0 auto auto;
  }

  & button:disabled {
    cursor: not-allowed;
    display: flex;
    justify-content: center;
  }

  @media screen and (min-width: 900px) {
    margin: 0;
  }
`;

export const SpinnerCont = styled.div`
  margin: 0 auto;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FieldSet = styled.fieldset`
  width: 100%;
  border: none;
`;

export const FieldLegend = styled.legend`
  font-size: 14px;
  color: #8c8c8c;
  margin-bottom: 22px;
`;

export const PhoneBtnCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 20px;

  & button {
    margin: 0;
  }
`;
