import * as S from './SubTitle.styled';
import { Props, SubTitleProps } from './interfaces';

const SubTitle = ({ subTitleProps, ...restProps }: Props) => {
  const { text, color }: SubTitleProps = subTitleProps || {
    text: 'subtitulo',
    color: 'blue',
  };

  return (
    <S.SubTitle {...restProps} color={color}>
      {text}
    </S.SubTitle>
  );
};

export default SubTitle;
