import { useTextArea } from './hooks';
import { Props } from './interfaces';

import * as S from './FormTextArea.styled';

const FormTextArea = (textAreaProps: Props) => {
  const {
    formTextAreaProps: { label, errorMessage, withCheck, fromStartValidation },
    restProps,
    focused,
    handleFocusedBlur,
    handleFocusedFocus,
  } = useTextArea(textAreaProps);

  return (
    <S.FormTextArea className={restProps.className} style={restProps.style}>
      <S.Label htmlFor={restProps.id}>{label}</S.Label>
      <S.TextAreaCont
        data-focused={fromStartValidation ? !!errorMessage : !!errorMessage && focused}
        data-pass={!errorMessage && !!restProps.value}
        tabIndex={0}
      >
        <S.TextArea {...restProps} onBlur={handleFocusedBlur} onFocus={handleFocusedFocus} />
        {withCheck ? (
          <S.IsCheck pass={!errorMessage && restProps.value ? 'true' : 'false'} />
        ) : null}
      </S.TextAreaCont>
      <S.Error>{errorMessage}</S.Error>
    </S.FormTextArea>
  );
};

export default FormTextArea;
