import { DivProps } from 'react-html-props';
import styled, { css } from 'styled-components';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { GobackProps } from './interfaces';

export const GoBack = styled.div.attrs((props: DivProps) => {
  return props;
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Arrow = styled(FaArrowAltCircleLeft).attrs((props: GobackProps) => {
  return props;
})`
  margin-top: 18px;
  font-size: 41px;
  background-color: white;
  border-radius: 50%;

  ${({ $arrowColor }) =>
    $arrowColor === 'sun yellow'
      ? css`
          color: var(--color-sun-yellow);
        `
      : $arrowColor === 'turquoise'
      ? css`
          color: var(--color-turquoise);
        `
      : $arrowColor === 'coral'
      ? css`
          color: var(--color-coral);
        `
      : $arrowColor === 'sand'
      ? css`
          color: var(--color-sand);
        `
      : $arrowColor === 'blue'
      ? css`
          color: var(--color-blue);
        `
      : css`
          color: white;
        `};
`;

export const Text = styled.p.attrs((props: GobackProps) => {
  return props;
})`
  font-size: 16px;
  margin-top: 8px;
  ${({ $textColor }) =>
    $textColor === 'sun yellow'
      ? css`
          color: var(--color-sun-yellow);
        `
      : $textColor === 'turquoise'
      ? css`
          color: var(--color-turquoise);
        `
      : $textColor === 'coral'
      ? css`
          color: var(--color-coral);
        `
      : $textColor === 'sand'
      ? css`
          color: var(--color-sand);
        `
      : $textColor === 'blue'
      ? css`
          color: var(--color-blue);
        `
      : css`
          color: white;
        `};
`;
