import { createGlobalStyle } from 'styled-components';
//import
// avenirLight,
// avenirBook,
// avenirBlack,
// avenirHeavy,
//avenirMedium,
//'infrastructure/assets';

const GlobalStyle = createGlobalStyle`
/* @font-face {
  font-family: 'Avenir';
  src: url('./infrastructure/assets/fonts/avenir_lt_std_35_light.ttf')
  format('truetype');
  font-weight: lighter;
  font-style: normal;
} */

:root {

  /*
* =============== 
* Variables
* ===============
*/

/*
* Page Main color 
*/

  --color-white: #fff;
  --color-text-white: #f0E4D6;
  --color-blue: #00738F;
  --color-turquoise: #0494A4;
  --color-sand: #F0E4D6;
  --color-coral: #F05923;
  --color-sun-yellow: #F5A931 ; 


/*
* Trinkes 
*/
  --transition-fast: all 0.2s ease-in-out;
  --transition: all 0.3s ease-in-out;
  --transition-slow: all 0.6s ease-in-out;
  --transition-very-slow: all 0.9s ease-in-out;
  --spacing: 0.1rem;
  --subtitle-spacing: 3px;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell,
    'Open Sans',  sans-serif; ;
  
  
}

/*
* =============== 
* Global Styles
* ===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html,
body {
  height: 100%;
  background-color: var(--color-sand);
}

:root {
  font-family: var(--font-family);
  color: var(--color-text-white);
}



ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  line-height: 1.25;
}

h1 {
  font-size: 2.79rem;
}

h2 {
  font-size: 1.8rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 0.875rem;
}

p {
  color: var(--clr-grey-5);
}

/*
* ==================== 
* Custom Project Style
* ====================
*/

#root {
  min-height: 100%;
}

`;

export default GlobalStyle;
