import { FormValueProps, FormErrorsProps } from '../interfaces';

export const formValues: FormValueProps = {
  name: '',
  lastname: '',
  email: '',
  message: '',
};

export const formErrors: FormErrorsProps = {
  name: '',
  lastname: '',
  email: '',
  message: '',
};
