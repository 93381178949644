import { FormValueProps, FormErrorsProps } from '../interfaces';
import { isValidPhoneNumber } from 'libphonenumber-js';

const validation = ({ name, lastname, email, phone }: FormValueProps) => {
  const errors: FormErrorsProps = {
    name: '',
    lastname: '',
    email: '',
    phone: '',
  };

  //Validation for name
  if (!name.trim()) {
    errors.name = 'Este campo es requerido';
  } else if (name.trim().length < 3 || name.trim().length > 30) {
    errors.name = 'solo 3 to 30 caracteres son permitidos';
  } else if (/[0-9]/.test(name.trim())) {
    errors.name = 'Los numeros no son permitidos';
  } else if (name.trim() && !/^[a-zA-Z ]*$/.test(name.trim())) {
    errors.name = 'Caracteres especiales, no son permitidos';
  } else {
    errors.name = '';
  }

  //Validation for lastName
  if (!lastname.trim()) {
    errors.lastname = 'Este campo es requerido';
  } else if (lastname.trim().length < 3 || lastname.trim().length > 30) {
    errors.lastname = 'solo 3 to 30 caracteres son permitidos';
  } else if (/[0-9]/.test(lastname.trim())) {
    errors.lastname = 'Los numeros no son permitidos';
  } else if (lastname.trim() && !/^[a-zA-Z ]*$/.test(lastname.trim())) {
    errors.lastname = 'Caracteres especiales, no son permitidos';
  } else {
    errors.lastname = '';
  }

  //Validation for email
  if (!email.trim()) {
    errors.email = 'Este campo es requerido';
  } else if (
    email.trim() &&
    !email
      .trim()
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    errors.email = 'Usar un correo valido';
  } else {
    errors.email = '';
  }

  ///phone;
  if (!phone.trim()) {
    errors.phone = 'Este campo es requerido';
  } else if (phone.trim() && !isValidPhoneNumber(phone)) {
    errors.phone = 'Usar un telefono valido';
  } else {
    errors.phone = '';
  }

  return errors;
};

export default validation;
