import * as S from './GoBack.styled';
import { Props, GobackProps } from './interfaces';

const GoBack = ({ gobackProps, ...restProps }: Props) => {
  const { $arrowColor, $textColor }: GobackProps = gobackProps || {
    $arrowColor: 'blue',
    $textColor: 'blue',
  };

  return (
    <S.GoBack {...restProps}>
      <S.Arrow $arrowColor={$arrowColor} />
      <S.Text $textColor={$textColor}>Regresar</S.Text>
    </S.GoBack>
  );
};

export default GoBack;
