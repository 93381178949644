import { MainCont, Logo, Title, SubTitle, ClientSoftRegistration } from 'infrastructure/components';
import { lasOlasIcon, wifiIcon } from 'infrastructure/assets';
import * as S from './Registration.style';

const Registration = () => {
  return (
    <MainCont>
      <S.RegistrationInnerCont>
        <S.LogoCont>
          <Logo src={lasOlasIcon} alt='Las Olas Logo' />
        </S.LogoCont>
        <S.GreetingCont>
          <Title
            style={{
              textAlign: 'center',
            }}
            titleProps={{
              text: 'Bienvenidos a Las Olas Hotel',
            }}
          />
          <SubTitle
            style={{
              textAlign: 'center',
            }}
            subTitleProps={{
              text: 'Registrate con nosotros para obtener acceso a más actividades y servicios.',
            }}
          />
        </S.GreetingCont>
        <ClientSoftRegistration />
        <S.WifiIconCont>
          <Logo src={wifiIcon} alt='wifi' />
        </S.WifiIconCont>
        <S.Text>Zona Wifi</S.Text>
      </S.RegistrationInnerCont>
    </MainCont>
  );
};

export default Registration;
