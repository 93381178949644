import styled, { css } from 'styled-components';
import { CTAButtonProps } from './interfaces';

export const CTAButton = styled.button.attrs((props: CTAButtonProps) => {
  return props;
})`
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 210px;
  height: 55px;
  border-radius: 12px;
  font-family: inherit;
  font-size: 22px;
  border: 1px solid ${({ $withBorder }) => ($withBorder ? 'white' : 'transparent')};
  padding: 20px 10px;
  cursor: pointer;

  ${({ color }) =>
    color === 'sun yellow'
      ? css`
          background-color: var(--color-sun-yellow);
        `
      : color === 'turquoise'
      ? css`
          background-color: var(--color-turquoise);
        `
      : color === 'coral'
      ? css`
          background-color: var(--color-coral);
        `
      : color === 'sand'
      ? css`
          background-color: var(--color-sand);
          color: var(--color-blue);
        `
      : css`
          background-color: var(--color-blue);
        `};
`;
