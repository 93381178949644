import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ActivitiesInnerCont = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow-y: auto; */
`;

export const LogoCont = styled.div`
  margin-top: 20px;
  width: 84px;
  margin-bottom: 20px;

  @media screen and (min-width: 600px) {
    transform: scale(1.2);
  }
`;

export const ButtonCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(100%, 350px);
`;

export const LinkTo = styled(Link)`
  width: 100%;
  margin-bottom: 15px;
`;
