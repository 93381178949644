import { MainCont, Logo, SelectionButton } from 'infrastructure/components';
import { lasOlasWithTextIcon } from 'infrastructure/assets';
import * as S from './Selection.styled';

const Selection = () => {
  return (
    <MainCont>
      <S.SelectionInnerCont>
        <S.LogoCont>
          <Logo src={lasOlasWithTextIcon} alt='Las Olas complete Logo' />
        </S.LogoCont>
        <S.ButtonCont>
          <S.LinkTo to='/recepcion'>
            <SelectionButton
              selectionButtonProps={{
                text: 'Recepción',
                color: 'sand',
              }}
            />
          </S.LinkTo>
          <S.LinkTo to='/tambor-chino'>
            <SelectionButton
              selectionButtonProps={{
                text: 'Tambor Chino',
                color: 'coral',
              }}
            />
          </S.LinkTo>
          <S.LinkTo to='/neveri-kayak'>
            <SelectionButton
              selectionButtonProps={{
                text: 'Neverí Kayak',
                color: 'sun yellow',
              }}
            />
          </S.LinkTo>
          <S.LinkTo to='/actividades'>
            <SelectionButton
              selectionButtonProps={{
                text: 'Actividades',
                color: 'turquoise',
              }}
            />
          </S.LinkTo>
          <S.LinkTo to='/master-driver'>
            <SelectionButton
              selectionButtonProps={{
                text: 'Master Drivers',
                color: 'coral',
              }}
            />
          </S.LinkTo>
          <S.LinkTo to='/recomendaciones' style={{ marginBottom: '5px' }}>
            <SelectionButton
              style={{
                margin: '5px auto 0 auto',
                height: '35px',
                width: '230px',
                fontSize: '16px',
                fontWeight: '600',
              }}
              selectionButtonProps={{
                text: 'Recomendaciones',
                color: 'sun yellow',
              }}
            />
          </S.LinkTo>
          <S.LinkTo to='https://goo.gl/maps/a17xoMvUJeaURCe97' target='_blank'>
            <SelectionButton
              style={{
                width: '230px',
                margin: '0 auto',
                height: '35px',
                padding: '0 12px',
                fontSize: '16px',
                fontWeight: '600',
              }}
              selectionButtonProps={{
                text: 'Compatir experiencia',
                color: 'sun yellow',
              }}
            />
          </S.LinkTo>
        </S.ButtonCont>
      </S.SelectionInnerCont>
    </MainCont>
  );
};

export default Selection;
