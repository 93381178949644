import * as S from './Footer.styled';

const Footer = () => {
  return (
    <S.Footer>
      <S.LinkTo to='/'>
        <S.GoHome />
      </S.LinkTo>
    </S.Footer>
  );
};

export default Footer;
