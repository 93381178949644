import { MainCont, Logo, CTAButton, Title, GoBack } from 'infrastructure/components';
import { useNavigate } from 'react-router-dom';
import { masterDriverIcon } from 'infrastructure/assets';
import * as S from './MasterDrivers.styled';
import { useMemo, useState } from 'react';

const MasterDrivers = () => {
  const [activeIndex, setActiveIndex] = useState({
    call: 0,
    whastapp: 0,
  });

  const navigate = useNavigate();

  const carouselTo = (goTo: 'left' | 'right', type: 'call' | 'whastapp') => () => {
    if (type === 'call') {
      const last = callNumber.length - 1;

      if (goTo === 'left') {
        if (activeIndex.call === 0) {
          setActiveIndex((prevState) => ({ ...prevState, call: last }));
        } else {
          setActiveIndex((prevState) => ({ ...prevState, call: prevState.call - 1 }));
        }
      } else {
        if (activeIndex.call === last) {
          setActiveIndex((prevState) => ({ ...prevState, call: 0 }));
        } else {
          setActiveIndex((prevState) => ({ ...prevState, call: prevState.call + 1 }));
        }
      }
    } else {
      const last = whastappNumber.length - 1;

      if (goTo === 'left') {
        if (activeIndex.whastapp === 0) {
          setActiveIndex((prevState) => ({ ...prevState, whastapp: last }));
        } else {
          //setactiveWhatsappNumber((prevState) => prevState - 1);

          setActiveIndex((prevState) => ({ ...prevState, whastapp: prevState.whastapp - 1 }));
        }
      } else {
        if (activeIndex.whastapp === last) {
          setActiveIndex((prevState) => ({ ...prevState, whastapp: 0 }));
        } else {
          setActiveIndex((prevState) => ({ ...prevState, whastapp: prevState.whastapp + 1 }));
        }
      }
    }
  };

  const callNumber = useMemo(
    () => [
      {
        to: 'tel:+584129445477',
        text: 'Llamar 1',
      },

      {
        to: 'tel:+584265808333',
        text: 'Llamar 2',
      },
      {
        to: 'tel:+584122800715',
        text: 'Llamar 3',
      },
      {
        to: 'tel:+584125252292',
        text: 'Llamar 4',
      },
    ],
    []
  );

  const whastappNumber = useMemo(
    () => [
      {
        to: 'https://wa.link/1wfxwq',
        text: 'Whastapp 1',
      },

      {
        to: 'https://wa.me/+584265808333',
        text: 'Whastapp 2',
      },
      {
        to: 'https://wa.me/+584122800715',
        text: 'Whastapp 3',
      },
      {
        to: 'https://wa.me/+584125252292',
        text: 'Whastapp 4',
      },
    ],
    []
  );

  return (
    <MainCont color='coral'>
      <S.MasterDriversInnerCont>
        <Title
          style={{
            marginTop: '80px',
          }}
          titleProps={{
            text: 'Master Drivers',
          }}
        />
        <S.LogoCont>
          <Logo src={masterDriverIcon} alt='master Driver  Logo' />
        </S.LogoCont>
        <S.ButtonCont>
          <S.ButtonCarouselCont>
            <S.ButtonCarouselLeft onClick={carouselTo('left', 'call')} />
            <S.ButtoCarouselInnerCont>
              {callNumber.map(({ text, to }, i) => {
                let position: 'active' | 'left' | 'right' =
                  i === activeIndex.call
                    ? 'active'
                    : i === activeIndex.call - 1 ||
                      (activeIndex.call === 0 && i === callNumber.length - 1)
                    ? 'left'
                    : 'right';

                return (
                  <S.LinkTo to={to} target='_blank' key={i} position={position}>
                    <CTAButton
                      CTAButtonProps={{
                        $withBorder: true,
                        text,
                        color: 'sun yellow',
                      }}
                    />
                  </S.LinkTo>
                );
              })}
            </S.ButtoCarouselInnerCont>
            <S.ButtonCarouselRight onClick={carouselTo('right', 'call')} />
          </S.ButtonCarouselCont>

          <S.ButtonCarouselCont>
            <S.ButtonCarouselLeft onClick={carouselTo('left', 'whastapp')} />
            <S.ButtoCarouselInnerCont>
              {whastappNumber.map(({ text, to }, i) => {
                let position: 'active' | 'left' | 'right' =
                  i === activeIndex.whastapp
                    ? 'active'
                    : i === activeIndex.whastapp - 1 ||
                      (activeIndex.whastapp === 0 && i === callNumber.length - 1)
                    ? 'left'
                    : 'right';

                return (
                  <S.LinkTo to={to} target='_blank' key={i} position={position}>
                    <CTAButton
                      CTAButtonProps={{
                        $withBorder: false,
                        text,
                        color: 'turquoise',
                      }}
                    />
                  </S.LinkTo>
                );
              })}
            </S.ButtoCarouselInnerCont>
            <S.ButtonCarouselRight onClick={carouselTo('right', 'whastapp')} />
          </S.ButtonCarouselCont>

          <S.LinkTo to='https://www.instagram.com/masterdriversvzla' target='_blank'>
            <CTAButton
              CTAButtonProps={{
                $withBorder: false,
                text: 'Instagram',
                color: 'blue',
              }}
            />
          </S.LinkTo>
        </S.ButtonCont>
        <GoBack
          onClick={() => navigate(-1)}
          gobackProps={{
            $arrowColor: 'sun yellow',
          }}
        />
      </S.MasterDriversInnerCont>
    </MainCont>
  );
};

export default MasterDrivers;
