import { useInput } from './hooks';
import { Props } from './interfaces';

import * as S from './FormInput.styled';

const FormInput = (formInput: Props) => {
  const {
    formInputProps: { label, errorMessage, withCheck, fromStartValidation },
    restProps,
    focused,
    handleFocusedBlur,
    handleFocusedFocus,
  } = useInput(formInput);

  return (
    <S.FormInput
      className={restProps.className}
      style={restProps.style}
      data-ischeckbox={restProps.type === 'checkbox'}
    >
      {label ? <S.Label htmlFor={restProps.id}>{label}</S.Label> : null}
      <S.Input
        {...restProps}
        onBlur={handleFocusedBlur}
        onFocus={handleFocusedFocus}
        data-focused={fromStartValidation ? !!errorMessage : !!errorMessage && focused}
        data-pass={!errorMessage && !!restProps.value}
      />

      {withCheck ? <S.IsCheck $top={!!label} $pass={!errorMessage && !!restProps.value} /> : null}
      <S.Span>{errorMessage}</S.Span>
    </S.FormInput>
  );
};

export default FormInput;
