import { MainCont, Logo, CTAButton, Title, GoBack } from 'infrastructure/components';
import { useNavigate } from 'react-router-dom';
import { grupoTuristicoBahia } from 'infrastructure/assets';
import * as S from './GrupoTuristicoBahia.styled';

const GrupoTuristicoBahia = () => {
  const navigate = useNavigate();

  return (
    <MainCont color='sand'>
      <S.GrupoTuristicoBahiaInnerCont>
        <Title
          style={{
            marginTop: '80px',
          }}
          titleProps={{
            color: 'blue',
            text: 'Grupo Turistico Bahía',
          }}
        />
        <S.LogoCont>
          <Logo src={grupoTuristicoBahia} alt='Grupo turistico bahia Logo' />
        </S.LogoCont>
        <S.ButtonCont>
          <S.LinkTo to='tel:+34638395061' target='_blank'>
            <CTAButton
              CTAButtonProps={{
                $withBorder: false,
                text: 'Llamar',
                color: 'coral',
              }}
            />
          </S.LinkTo>
          <S.LinkTo to='https://wa.link/2rxn4l' target='_blank'>
            <CTAButton
              CTAButtonProps={{
                $withBorder: false,
                text: 'Whastapp',
                color: 'turquoise',
              }}
            />
          </S.LinkTo>

          <S.LinkTo to='https://www.instagram.com/bahiaspot' target='_blank'>
            <CTAButton
              CTAButtonProps={{
                $withBorder: false,
                text: 'Instagram',
                color: 'blue',
              }}
            />
          </S.LinkTo>
        </S.ButtonCont>
        <GoBack
          onClick={() => navigate(-1)}
          gobackProps={{
            $arrowColor: 'coral',
            $textColor: 'coral',
          }}
        />
      </S.GrupoTuristicoBahiaInnerCont>
    </MainCont>
  );
};

export default GrupoTuristicoBahia;
