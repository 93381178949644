import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import GlobalStyle from 'globalStyle';
import Meta from 'infrastructure/meta/Meta';

createRoot(document.getElementById('root') as HTMLElement, {
  identifierPrefix: 'id-',
}).render(
  <React.StrictMode>
    <GlobalStyle />
    <Meta
      title='Las Olas Redirection Landing'
      author='Ikusa Media'
      description='IKUSA MEDIA the marketing powerhouse'
    >
      <App />
    </Meta>
  </React.StrictMode>
);
