import { Navigate, Outlet } from 'react-router-dom';

interface Props {
  isProtected?: boolean;
}

const AppProtectedRoutes = ({ isProtected }: Props) => {
  const previousSend = localStorage.getItem('previousSend');

  if (isProtected) {
    return previousSend === 'true' ? <Outlet /> : <Navigate to='/Registration' replace />;
  } else {
    return <Outlet />;
  }
};

export default AppProtectedRoutes;
