import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SoftRegistration } from '../interfaces';
import axios from 'axios';
import { useState } from 'react';

const useSendRecomendation = () => {
  const MySwal = withReactContent(Swal);
  const [isSendingRecomendation, SetIsSendingRecomendation] = useState(false);

  const sendRecomendation = async ({
    values,
    initValues,
    setIsValidating,
    setValues,
  }: SoftRegistration) => {
    SetIsSendingRecomendation(true);

    try {
      const dataToSend = { ...values, type: 'suggestion' };
      await axios.post('https://las-olas.up.railway.app/api', dataToSend);
      SetIsSendingRecomendation(false);
      MySwal.fire({
        toast: true,
        icon: 'success',
        title: 'Felicidades, su recomendacion ha sido enviada con exito',
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
      });
    } catch (error) {
      let errorMessage =
        'Opps, a ocurrido un error, por favor verifique su conexión a internet e intente nuevamente';
      SetIsSendingRecomendation(false);
      MySwal.fire({
        toast: true,
        icon: 'error',
        title: errorMessage,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
    } finally {
      setIsValidating(false);
      setValues({ ...initValues });
    }
  };

  return { sendRecomendation, isSendingRecomendation };
};

export default useSendRecomendation;
