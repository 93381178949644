import { Props, SelectionButtonProps } from './interfaces';
import * as S from './SelectionButton.styled';

const SelectionButton = ({ selectionButtonProps, ...restProps }: Props) => {
  const { text, color }: SelectionButtonProps = selectionButtonProps || {
    color: 'blue',
    text: 'text',
  };

  return (
    <S.SelectionButton {...restProps} color={color}>
      {text}
    </S.SelectionButton>
  );
};

export default SelectionButton;
