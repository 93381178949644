import { Outlet } from 'react-router-dom';
import { Footer } from 'infrastructure/components';
import { palm } from 'infrastructure/assets';
import * as S from './LayoutRoot.styled';

const Root = () => {
  return (
    <S.LayoutRoot data-component-name='Layout Root' style={{ backgroundImage: `url(${palm})` }}>
      <Outlet />
      <Footer />
    </S.LayoutRoot>
  );
};

export default Root;
